$primary-color: #192BC2;
$secondary-color: #78C0E0;
$tertiary-color: #150578;

$white-color: #fff;
$black-color: #000;
$grey-color: #656973;

$elite-color: #0c64a2;

$bg-color: rgb(248, 248, 248);