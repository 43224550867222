.input-height {
  height: 52px !important;
}

.primary {
  color: #007bff;
}

.info {
  color: #17a2b8;
}

.warning {
  color: #ffc107;
}

.danger {
  color: #dc3545;
}

.success {
  color: #28a745;
}

.object-cover {
  object-fit: cover;
}

.grey-color {
  color: #656973;
}

.link-text {
  text-decoration: none;
  color: $elite-color;
}

.link-text:hover {
  color: $tertiary-color;
}

.link-black {
  text-decoration: none;
  color: $black-color;
}

.link-black:hover {
  color: $primary-color;
  text-decoration: none;
}